import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';

const socket = io.connect('https://socket.mertcanureten.com.tr');

const VoteDisplay = () => {
    const [votes, setVotes] = useState([]);
    const [totalVotes, setTotalVotes] = useState({
        total_candidate_1_votes: 0,
        total_candidate_2_votes: 0,
        total_votes: 0,
    });

    useEffect(() => {
        // Sandık bilgilerini al
        fetch('https://socket.mertcanureten.com.tr/api/ballot-boxes')
            .then((res) => res.json())
            .then((data) => {
                const votesDataPromises = data.map((box) =>
                    fetch(`https://socket.mertcanureten.com.tr/api/votes/${box.id}`).then((res) => res.json())
                );

                Promise.all(votesDataPromises).then((votesData) => setVotes(votesData));
            });

        // Toplam oy bilgilerini al
        const fetchTotalVotes = () => {
            fetch('https://socket.mertcanureten.com.tr/api/total-votes')
                .then((res) => res.json())
                .then((data) => setTotalVotes(data));
        };

        fetchTotalVotes();

        // Socket.IO ile anlık güncellemeleri dinle
        socket.on('votesUpdated', (updatedData) => {
            console.log('Güncellenen veriler:', updatedData);  // Gelen güncellemeyi logla
            setVotes((prevVotes) =>
                prevVotes.map((vote) =>
                    vote.ballot_box_id === parseInt(updatedData.id)
                        ? { ...vote, candidate_1_votes: updatedData.candidate_1_votes, candidate_2_votes: updatedData.candidate_2_votes }
                        : vote
                )
            );
            
            // Toplam oyları güncelle
            fetchTotalVotes();
        });

        return () => {
            socket.off('votesUpdated');
        };
    }, []);

    return (
        <div className="container mx-auto mt-8">
            <h1 className="text-2xl font-bold text-center mb-4">Sandık Durumu</h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {votes.map((vote, index) => (
                    <div key={index} className="p-4 border rounded-lg shadow">
                        <h2 className="text-xl font-semibold">Sandık {vote.ballot_box_id}</h2>
                        <p>Onur Çabuk: {vote.candidate_1_votes}</p>
                        <p>Emre Demir: {vote.candidate_2_votes}</p>
                    </div>
                ))}
            </div>
            {/* Toplam oyları göster */}
            <div className="mt-8 p-4 border rounded-lg shadow">
                <h2 className="text-xl font-semibold">Toplam Oy Durumu</h2>
                <p>Toplam Kullanılan Oy: {totalVotes.total_votes}</p>
                <p className='text-2xl font-bold'>Onur Çabuk: {totalVotes.total_candidate_1_votes}</p>
                <p className='text-2xl font-bold'>Emre Demir: {totalVotes.total_candidate_2_votes}</p>
            </div>
        </div>
    );
};

export default VoteDisplay;
