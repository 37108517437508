import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const BallotBoxSelection = () => {
    const [ballotBoxes, setBallotBoxes] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('https://socket.mertcanureten.com.tr/api/ballot-boxes')
            .then((res) => res.json())
            .then((data) => setBallotBoxes(data));
    }, []);

    return (
        <div className="container mx-auto mt-8">
            <h1 className="text-2xl font-bold text-center mb-4">Sandık Seçimi</h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {ballotBoxes.map((box) => (
                    <div
                        key={box.id}
                        className="p-4 border rounded-lg shadow cursor-pointer"
                        onClick={() => navigate(`/entry/${box.id}`)}
                    >
                        <h2 className="text-xl font-semibold">{box.name}</h2>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BallotBoxSelection;
