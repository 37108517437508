import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BallotBoxSelection from './pages/BallotBoxSelection';
import VoteEntry from './pages/VoteEntry';
import VoteDisplay from './pages/VoteDisplay';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<BallotBoxSelection />} />
                <Route path="/entry/:id" element={<VoteEntry />} />
                <Route path="/display" element={<VoteDisplay />} />
            </Routes>
        </Router>
    );
}

export default App;
