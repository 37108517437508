import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';

const socket = io.connect('https://socket.mertcanureten.com.tr');

const VoteEntry = () => {
    const { id } = useParams();
    const [votes, setVotes] = useState({ candidate_1_votes: 0, candidate_2_votes: 0 });
    const [loading, setLoading] = useState(true); // Yüklenme durumu

    useEffect(() => {
        fetch(`https://socket.mertcanureten.com.tr/api/votes/${id}`)
            .then((res) => res.json())
            .then((data) => {
                setVotes(data);
                setLoading(false); // Yükleme tamamlandı
            });

        // Socket.IO bağlantısı
        socket.on('votesUpdated', (updatedData) => {
            if (updatedData.id === parseInt(id)) {
                setVotes({
                    candidate_1_votes: updatedData.candidate_1_votes,
                    candidate_2_votes: updatedData.candidate_2_votes,
                });
            }
        });

        return () => {
            socket.off('votesUpdated');
        };
    }, [id]);

    const updateVotes = (candidate, change) => {
        const newVotes = {
            ...votes,
            [candidate]: Math.max(0, votes[candidate] + change), // Oylar negatif olamaz
        };

        setVotes(newVotes);

        // Backend'e POST isteği gönder
        fetch(`https://socket.mertcanureten.com.tr/api/votes/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                candidate_1_votes: newVotes.candidate_1_votes,
                candidate_2_votes: newVotes.candidate_2_votes,
            }),
        });
    };

    return (
        <div className="container mx-auto mt-8">
            <h1 className="text-3xl font-bold text-center mb-8">Sandık {id} - Oy Girişi</h1>

            {loading ? (
                <div className="text-center text-2xl">Yükleniyor...</div> // Yüklenme durumu
            ) : (
                <div className="flex flex-col items-center gap-12">
                    {/* Onur Çabuk için oy girişi */}
                    <div className="flex items-center gap-6 w-full justify-between max-w-md">
                        <div className='flex gap-2'>
                            <button
                                className="px-6 py-4 bg-red-500 text-white rounded-lg text-4xl"
                                onClick={() => updateVotes('candidate_1_votes', -1)}
                                disabled={votes.candidate_1_votes === 0 || loading} // Veri yüklenmediyse buton devre dışı
                            >
                                -
                            </button>
                        </div>
                        <div className="text-xl w-1/2 text-center">
                            <h2 className="text-2xl font-semibold">Onur Çabuk</h2>
                            <div className="text-3xl mt-4">{votes.candidate_1_votes}</div>
                        </div>
                        <div className="flex gap-2">
                            <button
                                className="px-6 py-4 bg-green-500 text-white rounded-lg text-4xl"
                                onClick={() => updateVotes('candidate_1_votes', 1)}
                                disabled={loading} // Veri yüklenmediyse buton devre dışı
                            >
                                +
                            </button>
                        </div>
                    </div>

                    {/* Emre Demir için oy girişi */}
                    <div className="flex items-center gap-6 w-full justify-between max-w-md">
                        <div className='flex gap-2'>
                            <button
                                className="px-6 py-4 bg-red-500 text-white rounded-lg text-4xl"
                                onClick={() => updateVotes('candidate_2_votes', -1)}
                                disabled={votes.candidate_2_votes === 0 || loading} // Veri yüklenmediyse buton devre dışı
                            >
                                -
                            </button>
                        </div>
                        <div className="text-xl w-1/2 text-center">
                            <h2 className="text-2xl font-semibold">Emre Demir</h2>
                            <div className="text-3xl mt-4">{votes.candidate_2_votes}</div>
                        </div>
                        <div className="flex gap-2">
                            <button
                                className="px-6 py-4 bg-green-500 text-white rounded-lg text-4xl"
                                onClick={() => updateVotes('candidate_2_votes', 1)}
                                disabled={loading} // Veri yüklenmediyse buton devre dışı
                            >
                                +
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VoteEntry;
